import { Component } from "react";


export class Resume extends Component {
    render() {
        return (
            <div className="App-header">
            <h3>This is my Resume</h3>
            </div>
        )
    }
}