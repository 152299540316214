import logo from '../logo.svg';

export function Home() {
    return (
        <div className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <h3>
            Jonathan Hughes

            </h3>
            <p style={{ fontSize: '18px'}}>Source<a href="https://github.com/JehosafatJon/JehosafatJon.github.io" className='animated-link'>here</a></p>
            
        </div>
    )
}